class CenterType {
  constructor () {
    this.id = null
    this.name = null
    this.level = null
  }

  toString () {
    return `CenterType{id=${this.id}, name='${this.name}', level=${this.level}}`
  }
}
export default CenterType
