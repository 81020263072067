<template>
  <div>
    <div>
      <v-data-table
        :headers="headers"
        :items="rehabilitatedDelinquents"
        :page.sync="page"
        @page-count="pageCount = $event"
        :items-per-page="delinquentsPerPage"
        :server-items-length="totalDelinquents"
        class="elevation-1 ml-4"
        hide-default-footer
      >
        <template v-slot:top>
          <v-card flat color="white">
            <v-row>
              <v-col cols="12" sm="3">
                <v-select
                  :items="assessmentStatuses"
                  item-text="name"
                  item-value="id"
                  v-model="assessmentStatus"
                  dense
                  outlined
                  color="grey"
                  hint="Filter by Behavior Assessment Status"
                  persistent-hint
                  class="mt-8 mx-4"
                  solo
                />
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  v-model="searchName"
                  dense
                  outlined
                  color="grey"
                  hint="Filter by Name"
                  persistent-hint
                  class="mt-8 mx-4"
                  solo
                />
              </v-col>
              <v-col cols="12" sm="1">
                <v-btn
                  class="mt-8"
                  elevation="1"
                  fab
                  small
                  @click="filterBehaviorAssessment"
                >
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
              </v-col>
              <v-spacer></v-spacer>
              <v-btn rounded disabled class="mt-10">{{
                currentCenter.name
              }}</v-btn>
            </v-row>
            <v-row>
              <v-col cols="12" sm="10"> </v-col>
              <v-dialog v-model="dialog" max-width="1200px" persistent>
                <template v-slot:activator="{ on }">
                  <v-spacer></v-spacer>
                  <v-btn
                    color="green"
                    dark
                    class="mb-2"
                    v-on="on"
                    small
                    right
                    v-if="showCreate"
                    >Add Abehavior Assessment</v-btn
                  >
                </template>
                <v-card>
                  <v-form ref="form">
                    <v-row>
                      <v-col cols="11">
                        <v-card-title class="mb-12">
                          <span class="headline">{{ formTitle }}</span>
                        </v-card-title>
                      </v-col>
                      <v-col cols="1">
                        <v-spacer />
                        <v-btn
                          class="ml-0"
                          dark
                          icon
                          color="pink"
                          large
                          @click="close"
                          ><v-icon>mdi-close-circle</v-icon></v-btn
                        >
                      </v-col>
                    </v-row>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="12">
                            <v-select
                              v-model="behaviorAssessmentResult.transferId"
                              :items="rehabilitatedDelinquents"
                              :item-text="
                                item =>
                                  `${item.delinquent.firstName} ${item.delinquent.lastName}`
                              "
                              item-value="id"
                              :rules="[v => !!v || 'required']"
                              :disabled="viewmode"
                              solo
                            ></v-select>
                          </v-col>
                          <v-list flat>
                            <v-col cols="12" sm="12">
                              <p class="font-weight-medium">
                                General Socail psychological maladjusted
                                behaviors
                              </p>
                            </v-col>
                            <v-list-item
                              v-for="(item, i) in assessmentQuestions"
                              :key="i"
                            >
                              <v-col cols="12" sm="12">
                                <p>
                                  <span>{{ i + 1 }}</span
                                  >. {{ item.question }}
                                </p>
                                <v-radio-group
                                  v-model="
                                    behaviorAssessmentResult.selectedAnswerIDs[
                                      i
                                    ]
                                  "
                                  :mandatory="true"
                                  row
                                  :disabled="viewmode"
                                >
                                  <v-radio
                                    v-for="behavior in item.answerOptions"
                                    :key="behavior.id"
                                    :label="behavior.answerOption"
                                    :value="behavior.id"
                                  ></v-radio>
                                </v-radio-group>
                              </v-col>
                            </v-list-item>
                          </v-list>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions v-if="!viewmode">
                      <v-btn
                        color="green"
                        dark
                        class="ml-12 mt-n10 mb-4"
                        @click="onSubmit"
                        >Save</v-btn
                      >
                      <v-spacer />
                      <v-btn class="mt-n10 mb-4" @click="close">Cancel</v-btn>
                    </v-card-actions>
                    <v-card-actions v-else>
                      <v-btn
                        class="mt-n10 mb-4"
                        color="purple"
                        @click="deleteDialog = true"
                        ><v-icon>mdi-delete</v-icon> Delete</v-btn
                      >
                      <v-spacer />
                      <v-btn class="mt-n10 mb-4 red" @click="close"
                        >close</v-btn
                      >
                    </v-card-actions>
                  </v-form>
                </v-card>
              </v-dialog>
            </v-row>
          </v-card>
        </template>
        <template v-slot:item.id="{ index }">
          <span>{{ index + 1 }}</span>
        </template>
        <template v-slot:item.names="{ item }">
          <span @click="loadProfile(item)" class="btn blue--text"
            >{{ item.delinquent.firstName }}
            {{ item.delinquent.lastName }}</span
          >
        </template>
        <template v-slot:item.behaviorAssessmentStatus="{ item }">
          <span v-if="item.behaviorAssessmentStatus === 'PENDING'">{{
            item.behaviorAssessmentStatus
          }}</span>
          <span
            v-else
            @click="assignBehavionAssessment({ item, view: true })"
            class="btn blue--text"
            >{{ item.behaviorAssessmentStatus }}</span
          >
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="assignBehavionAssessment({ item, view: false })"
            v-if="showEdit && item.behaviorAssessmentStatus === 'PENDING'"
          >
            mdi-plus-circle
          </v-icon>
          <v-icon
            small
            @click="assignBehavionAssessment({ item, view: true })"
            v-if="showEdit && item.behaviorAssessmentStatus === 'COMPLETED'"
          >
            mdi-eye
          </v-icon>
        </template>
      </v-data-table>
    </div>
    <div></div>
    <v-snackbar v-model="snackbar" top :color="color" :timeout="timeout">
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" icon v-bind="attrs" @click="setSnackbar(false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="deleteDialog" hide-overlay max-width="500px">
      <v-card tile outlined>
        <v-card-text>
          <p>Are you sure you want to delete this trade class ?</p>
          <v-btn link small color="blue" @click="deleteAssessmentBehavior()"
            >Yes</v-btn
          >
          <v-btn link small color="blue" @click="deleteDialog = false"
            >No</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
    <div class="pt-2 px-12">
      <v-row>
        <v-col cols="12" sm="2">
          <v-select
            :items="selectDelinquentsPerPage"
            v-model="delinquentsPerPage"
            label="Delinquents per page"
            dense
            filled
          ></v-select>
        </v-col>
        <v-col cols="12" sm="10">
          <v-pagination
            v-model="page"
            :length="pageCount"
            circle
            total-visible="7"
            color="blue"
          ></v-pagination>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
// import { WebCam } from 'vue-web-cam'
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import TrainingClass from '@/models/TrainingClass'
export default {
  data () {
    return {
      selectDelinquentsPerPage: [10, 15, 20, 25, 30, 50, 100, 200],
      totalDelinquents: 0,
      page: 1,
      pageCount: 0,
      delinquentsPerPage: 20,
      viewmode: false,
      searchName: null,
      allRehabilitatedDelinquents: [],
      assessmentStatus: null,
      assessmentStatuses: ['ALL', 'PENDING', 'COMPLETED'],
      behaviorAssessmentResult: {
        transferId: '',
        selectedAnswerIDs: []
      },
      // assessmentQs: [],
      dialog: false,
      privileges: null,
      showEdit: false,
      showDelete: false,
      showCreate: false,
      deleteDialog: false,
      completeDialog: false,
      names: null,
      items: [
        { text: 'Real-Time', icon: 'mdi-clock' },
        { text: 'Audience', icon: 'mdi-account' },
        { text: 'Conversions', icon: 'mdi-flag' }
      ],

      headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'no'
        },
        { text: 'Names', value: 'names' },
        { text: 'Year Of Birth', value: 'delinquent.dateOfBirth' },
        { text: 'Intake', value: 'arrivalDate' },
        {
          text: 'Behavior Assessment Status',
          value: 'behaviorAssessmentStatus'
        },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      singleSelect: false,
      behaviours: [
        { label: 'Once', value: 'once' },
        { label: 'Occasionally', value: 'occasionally' },
        { label: 'Frequently', value: 'frequently' }
      ],
      first: null,
      second: null,
      third: null,
      fourth: null,
      fifth: null,
      sixth: null,
      seventh: null,
      selected: [],
      filterIntake: null,
      intakes: [],
      editedTrainingClassIndex: -1,
      editedTrainingClass: new TrainingClass(),
      trainingClassItem: null,
      transferId: null,
      errors: null,
      tomorrow1: null,
      panel: 3,
      delinquents: [],
      delinquentFirstName: null,
      delinquentLastName: null,
      placeOfBirthId: null,
      residenceId: null,
      recordDates: null,
      arrivalDates: null,
      exitDates: null,
      released: null,
      states: null,
      eligibilityStates: null,
      intakeId: null,
      intakeSet: null,
      decisionIds: null,
      physicallyScreened: null,
      screeningApprovalStatuses: null,
      onGraduationList: null,
      graduationId: null,
      expand: false,
      graduationApprovalStatuses: null,
      postRehabDestinationId: null,
      hasOccupation: null,
      organizationId: null,
      sort: 'recordDate,desc'
    }
  },

  computed: {
    formTitle () {
      return this.editedTrainingClassIndex === -1
        ? 'Add Behavior Assessment'
        : 'Edit Behavior Assessment'
    },
    ...mapState('store', [
      'snackbar',
      'color',
      'text',
      'timeout',
      'currentUser',
      'currentCenter'
    ]),
    assessmentQs () {
      return this.getAssessmentQs()
    },
    ...mapGetters('psychotherapy', ['assessmentQuestions']),
    ...mapGetters('delinquent', [
      'rehabilitatedDelinquents',
      'rehabilitatedDelinquentsByStatus',
      'rehabilitatedDelinquentsByName',
      'rehabilitatedDelinquentsByNameAndStatus'
    ])
  },

  watch: {
    dialog (val) {
      val || this.close()
    },
    page: {
      handler () {
        this.filterBehaviorAssessment()
      }
    },
    delinquentsPerPage: {
      handler () {
        this.page = 1
        this.filterBehaviorAssessment()
      }
    }
  },
  mounted () {
    this.loadRehabilitatedDelinquenst()
    this.getAssessmentQs()
    // this.loadAllDelinquents()
    this.privileges = this.currentUser.privileges
    this.checkPrivileges()
  },
  created () {
    this.showPanels = true
  },
  destroyed () {
    this.setSnackbar(false)
  },

  methods: {
    ...mapActions('store', ['switchToClass']),
    ...mapMutations('store', {
      setSnackbar: 'SET_SNACKBAR',
      setColor: 'SET_COLOR',
      setText: 'SET_TEXT',
      setDelinquentItem: 'SET_DELINQUENT_ITEM',
      setTrainingClass: 'SET_TRAINING_CLASS'
    }),
    ...mapActions('psychotherapy', ['getAssessmentQs']),
    ...mapActions('delinquent', [
      'readRehabilitatedDelinquents',
      'updateRehabilitatedDelinquents'
    ]),
    deleteAssessmentBehavior () {
      this.deleteDialog = false
      this.$psychotherapyService
        .deleteBehaviorAssessment(this.behaviorAssessmentResult.transferId)
        .then(res => {
          if (res.status !== 'success') {
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(res.message)
          } else {
            this.updateRehabilitatedDelinquents(
              this.behaviorAssessmentResult.transferId
            )
            this.close()
          }
        })
    },
    assignBehavionAssessment (payload) {
      this.viewmode = payload.view
      if (!payload.view) {
        // this.$refs.form.reset()
        this.behaviorAssessmentResult.transferId = payload.item.id
        this.dialog = true
      } else if (payload.view) {
        const found = []
        this.behaviorAssessmentResult.transferId = payload.item.id
        this.$psychotherapyService
          .getAssessmentQsById(payload.item.id)
          .then(res => {
            var prom = new Promise((resolve, reject) => {
              res.forEach((element, index, array) => {
                found.push(element.answerOptions.find(el => el.selected).id)
                if (index === array.length - 1) resolve()
              })
            })
            prom.then(() => {
              this.behaviorAssessmentResult.selectedAnswerIDs = found
              this.dialog = true
            })
          })
      }
    },
    loadProfile (item) {
      this.setDelinquentItem(item)
      this.$router.push('/delinquent-profile')
    },
    onSubmit () {
      if (this.$refs.form.validate()) {
        if (this.editedTrainingClassIndex > -1) {
          this.updateTrainingClass()
        } else {
          this.$psychotherapyService
            .saveBehaviorAssessment(this.behaviorAssessmentResult)
            .then(res => {
              if (res.status !== 'success') {
                this.setSnackbar(true)
                this.setColor('red')
                this.setText(res.message)
                this.close()
              } else {
                this.updateRehabilitatedDelinquents(
                  this.behaviorAssessmentResult.transferId
                )
                this.close()
              }
            })
        }
        // this.$nextTick(() => {
        //   this.$refs.form.reset()
        // })
      }
    },
    filterBehaviorAssessment () {
      this.$delinquentService
        .fetchTransfersSpecification({
          states: 'REHABILITATION',
          page: this.page - 1,
          size: this.delinquentsPerPage,
          behaviorAssessmentComplete:
            this.assessmentStatus === 'PENDING'
              ? false
              : this.assessmentStatus === 'COMPLETED'
                ? true
                : null
        })
        .then(response => {
          if (!response.status) {
            this.readRehabilitatedDelinquents(response.content)
            this.totalDelinquents = response.totalElements
          } else {
            // this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
            this.setSnackbar(true)
            this.setColor('red')
            this.setText('Read Delinquents: ' + response.message)
          }
        })
      // if (
      //   (this.searchName === null || this.searchName === '') &&
      //   this.assessmentStatus !== null
      // ) {
      //   if (this.assessmentStatus === 'ALL') { this.allRehabilitatedDelinquents = this.rehabilitatedDelinquents } else {
      //     this.allRehabilitatedDelinquents = this.rehabilitatedDelinquentsByStatus(
      //       this.assessmentStatus
      //     )
      //   }
      // } else if (this.searchName !== null && this.assessmentStatus === null) {
      //   this.allRehabilitatedDelinquents = this.rehabilitatedDelinquentsByName(
      //     this.searchName
      //   )
      // } else if (this.searchName !== null && this.assessmentStatus !== null) {
      //   if (this.assessmentStatus === 'ALL') {
      //     this.allRehabilitatedDelinquents = this.rehabilitatedDelinquentsByName(
      //       this.searchName
      //     )
      //   } else {
      //     this.allRehabilitatedDelinquents = this.rehabilitatedDelinquentsByNameAndStatus(
      //       this.searchName,
      //       this.assessmentStatus
      //     )
      //   }
      // }
    },
    // getAssessmentQs () {
    //   this.$psychotherapyService.getAssessmentQs().then(res => {
    //     console.log(res)
    //     return res
    //   })
    // },
    loadRehabilitatedDelinquenst () {
      let index = 0
      const rehabilitatedDelinquents1 = []
      this.$delinquentService
        .fetchTransfersSpecification({
          states: 'REHABILITATION',
          page: this.page - 1,
          size: this.delinquentsPerPage
        })
        .then(response => {
          if (!response.status) {
            response.content.forEach(el => {
              index = index + 1
              el.no = index
              rehabilitatedDelinquents1.push(el)
            })
            this.readRehabilitatedDelinquents(rehabilitatedDelinquents1)
            this.totalDelinquents = response.totalElements
          } else {
            // this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
            this.setSnackbar(true)
            this.setColor('red')
            this.setText('Read Delinquents: ' + response.message)
          }
        })
    },
    loadAllDelinquents () {
      const pageNumber = this.page - 1
      this.delinquentLastName = this.delinquentFirstName
      this.graduationApprovalStatuses = 'APPROVED'
      this.expand = true
      this.$delinquentService
        .fetchAllTransfersSpecification(
          this.delinquentFirstName,
          this.delinquentLastName,
          this.placeOfBirthId,
          this.residenceId,
          this.recordDates,
          this.arrivalDates,
          this.exitDates,
          this.released,
          this.states,
          this.eligibilityStates,
          this.intakeId,
          this.intakeSet,
          this.decisionIds,
          this.physicallyScreened,
          this.screeningApprovalStatuses,
          this.onGraduationList,
          this.graduationId,
          this.expand,
          this.graduationApprovalStatuses,
          this.postRehabDestinationId,
          this.hasOccupation,
          this.organizationId,
          pageNumber,
          this.delinquentsPerPage,
          this.sort
        )
        .then(response => {
          if (!response.status) {
            this.delinquents = response.content
            this.totalDelinquents = response.totalElements
            this.delinquents.forEach(element => {
              element.names =
                element.delinquent.firstName +
                ' ' +
                element.delinquent.lastName
              element.delinquent.dateOfBirth = this.$moment(
                element.delinquent.dateOfBirth
              ).format('YYYY')
              element.arrivalDate = this.$moment(element.arrivalDate).format(
                'DD-MMM-YYYY'
              )
            })
          } else {
            // this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
            this.setSnackbar(true)
            this.setColor('red')
            this.setText('Read Delinquents: ' + response.message)
          }
        })
    },

    close () {
      this.dialog = false
      this.$refs.form.reset()
      this.viewmode = false
      setTimeout(() => {
        this.editedTrainingClass = Object.assign(
          {},
          this.defaultTrainingClassItem
        )
        this.editedTrainingClassIndex = -1
      }, 300)
    },
    editTrainingClass (item) {
      if (item.state !== 'COMPLETED') {
        this.editedTrainingClassIndex = this.delinquents.indexOf(item)
        this.$delinquentService
          .fetchTrainingClassById(item.id, null)
          .then(response => {
            if (response.id) {
              this.editedTrainingClass = response
              this.trainingLevels = [response.trainingLevel]
              this.retrievedStartDate = response.startDate
              this.retrievedEndDate = response.endDate
            } else {
              this.setSnackbar(true)
              this.setColor('red')
              this.setText(response.message)
            }
          })
        this.dialog = true
      } else {
        this.setSnackbar(true)
        this.setColor('red')
        this.setText('This class is completed')
      }
    },
    trainingClassDialog (item) {
      if (item.state !== 'COMPLETED') {
        this.trainingClassIndex = this.delinquents.indexOf(item)
        this.trainingClassItem = item
        this.deleteDialog = true
      } else {
        this.setSnackbar(true)
        this.setColor('red')
        this.setText('This class is completed')
      }
    },
    deleteTrainingClass () {
      this.deleteDialog = false
      this.$delinquentService
        .deleteTrainingClass(this.trainingClassItem.id)
        .then(response => {
          if (response.status === 'success') {
            this.delinquents.splice(this.trainingClassIndex, 1)
          } else {
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
    },
    completeTrainingClassDialog (item) {
      this.trainingClassIndex = this.delinquents.indexOf(item)
      this.trainingClassItem = item
      this.completeDialog = true
    },
    completeTrainingClass () {
      if (this.trainingClassItem.state !== 'NOT_STARTED') {
        this.$delinquentService
          .completeTrainingClass(
            this.trainingClassItem.id,
            this.formattedCompleteDate
          )
          .then(response => {
            if (response.status === 'success') {
              this.delinquents[
                this.trainingClassIndex
              ].endDate = this.formattedCompleteDate
              this.delinquents[this.trainingClassIndex].state = 'COMPLETED'
              this.close()
            } else {
              this.setSnackbar(true)
              this.setColor('red')
              this.setText(response.message)
            }
          })
      } else {
        this.setSnackbar(true)
        this.setColor('red')
        this.setText('This class have never started')
      }
    },
    updateTrainingClass () {
      this.editedTrainingClass.startDate = this.formattedStartDate
      this.editedTrainingClass.endDate = this.formattedEndDate
      const deletedTrainingClassArray = this.delinquents.splice(
        this.editedTrainingClassIndex,
        1
      )
      this.$delinquentService
        .saveDelinquents(this.editedTrainingClass)
        .then(response => {
          if (response.id) {
            this.delinquents.splice(this.editedTrainingClassIndex, 0, response)
            this.close()
          } else {
            this.delinquents.splice(
              this.editedTrainingClassIndex,
              0,
              deletedTrainingClassArray[0]
            )
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
    },
    createTrainingClass () {
      this.editedTrainingClass.startDate = this.formattedStartDate
      this.editedTrainingClass.endDate = this.formattedEndDate
      this.$delinquentService
        .saveDelinquents(this.editedTrainingClass)
        .then(response => {
          if (response.id) {
            this.delinquents.push(response)
            this.close()
          } else {
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
    },
    checkPrivileges () {
      this.privileges.indexOf('CREATE_TRAININGCLASSES') > -1 ||
      this.privileges.indexOf('ALL_AUTHORITIES') > -1
        ? (this.showCreate = true)
        : (this.showCreate = false)
      this.privileges.indexOf('UPDATE_TRAININGCLASSES') > -1 ||
      this.privileges.indexOf('ALL_AUTHORITIES') > -1
        ? (this.showEdit = true)
        : (this.showEdit = false)
      this.privileges.indexOf('DELETE_TRAININGCLASSES') > -1 ||
      this.privileges.indexOf('ALL_AUTHORITIES') > -1
        ? (this.showDelete = true)
        : (this.showDelete = false)
      this.privileges.indexOf('CREATE_BEHAVIORASSESSMENTS') > -1 ||
      this.privileges.indexOf('ALL_AUTHORITIES') > -1
        ? (this.showCreate = true)
        : (this.showCreate = false)
    }
  }
}
</script>
<style scoped>
.btn {
  cursor: pointer;
}
</style>
