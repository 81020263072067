
class TrainingLevel {
  constructor () {
    this.id = null
    this.level = null
    this.code = null
    this.description = null
    this.training = null
    this.status = null
  }

  toString () {
    return `TrainingLevel{id=${this.id}, level='${this.level}', code='${this.code}', training='${this.training}', status='${this.status}'}`
  }
}

export default TrainingLevel
