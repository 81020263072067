import Training from './Training'
import TrainingLevel from './TrainingLevel'

class TrainingClass {
  constructor () {
    this.id = null
    this.name = null
    this.training = new Training()
    this.startDate = null
    this.endDate = null
    this.intake = null
    this.trainingLevel = new TrainingLevel()
    this.comment = null
    this.state = null
  }

  toString () {
    return `TrainingClass{id=${this.id}, name='${this.name}', training='${this.training}', startDate='${this.startDate}',
    endDate='${this.endDate}', intake='${this.intake}', trainingLevel='${this.trainingLevel}', comment='${this.comment}',
    state='${this.state}'}`
  }
}

export default TrainingClass
