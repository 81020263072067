import CenterType from './CenterType'

class Training {
  constructor () {
    this.id = null
    this.name = null
    this.outcome = null
    this.durationInMonths = null
    this.centerType = new CenterType()
    this.status = null
  }

  toString () {
    return `Training{id=${this.id}, name='${this.name}', outcome='${this.outcome}', durationInMonths='${this.durationInMonths}',
    centerType='${this.centerType}', status='${this.status}'}`
  }
}

export default Training
